import axios from 'axios'
import router from '../router'
import {getToken, removeToken} from "./cookies";
import {Toast} from 'vant';
// 请求地址
// let hostApi = 'http://testvip400.wbox.cn'
// 创建 axios 实例
let hostApi = process.env.VUE_APP_API_BASE_URL

// 创建一个axios实例
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

const service = axios.create({
    baseURL: hostApi,
    withCredentials: true, // 当跨域请求时发送cookie
    timeout: 5000000 // 请求超时时间限制
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在请求发送之前处理
        if (getToken()) {
            // 让每个请求携带令牌，请根据实际情况进行修改
            config.headers['Authorization'] = getToken()
        }
        return config
    },
    error => {
        // 请求错误处理
        console.log(error, '请求错误处理')
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        const code = parseInt(res.code)
        switch (code) {
            case 200: // 请求成功
                return res
            case 202406: // 请求成功
                return Promise.reject(res || 'Error')
            case 201403: // 未登录
                Toast({message: res.message + '，请重新登录', duration: 2000})
                setTimeout(() => {
                    resetLogin();
                }, 2000)
                return Promise.reject(res || 'Error')
            default: // 异常情况：请求未响应
                Toast(res.message)
                return Promise.reject(res || 'Error')
        }
    },
    error => {
        console.log('请求异常', error)
        return Promise.reject('请求异常')
    }
)

function resetLogin() {
    router.push({name: 'Login'})
    removeToken();
}

export default service
